export const STATUS = 'SET_STATUSES';
export const COUNTRY = 'SET_COUNTRIES';
export const TAGCATRGORIES = 'SET_TAGCATEGORIES';
export const TAGS = 'SET_TAGS';
export const DESTINATIONS = 'SET_DESTINATIONS';
export const PACKAGES = 'SET_PACKAGES';
export const CUSTOMERS = 'SET_CUSTOMERS';
export const ASSETCATEGORRY = 'SET_ASSET_CATEGORIES';
export const GENERALSTATUS = 'SET_GENERAL_STATUSES';
export const USERSTATUS = 'SET_USER_STATUSES';
export const BOOKINGSTATUS = 'SET_BOOKING_STATUSES'
export const CLEAR = 'CLEAR_ALL_DROPDOWNS';
export const USERROLE = 'USER_ROLE';
export const USERROLELIST = 'SET_USERROLELIST';
